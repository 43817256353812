table {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    font-size: 0.875rem;
}
table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

table tbody tr:last-of-type {
    border: none;
}
table>thead>tr:first-of-type {
    background-color: #f5f5f5;
}
table thead tr:first-of-type th {
    height: 2rem;
    vertical-align: middle;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
}
table th, table td {
    padding: 0 0.5rem !important;
    word-wrap: break-word;
    vertical-align: middle;
}
table tbody tr td {
    height: 40px;
    font-weight: 400;
}
.highcharts-credits {
    display: none;
}